import { useEffect } from "react";

export const Scroll = () => {
useEffect(() => {
    const handleScroll = () => {
        const pageTop = window.scrollY;
        const pageBottom = pageTop + window.innerHeight;
        const tags = document.querySelectorAll('.tag');

        tags.forEach((tag) => {
            const tagRect = tag.getBoundingClientRect();
            const tagTop = tagRect.top + pageTop;
            const tagBottom = tagTop + tagRect.height;
            const visibleHeight = Math.min(tagBottom, pageBottom) - Math.max(tagTop, pageTop);
            const visibilityPercentage = (visibleHeight / tagRect.height) * 100;
            if (visibilityPercentage >= 30 || tag.getBoundingClientRect().top < pageTop) {
                tag.classList.add('visible');
            } else {
                tag.classList.remove('visible');
            }
        });
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);
}