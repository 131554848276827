import React from 'react';
import { Navbar } from '../../Components/Navbar/Navbar';
import { Scroll } from '../../Components/Scroll/Scroll';

import "./JobCentral.css"

export const JobCentral = () => {

  Scroll();

  return (
    <div>
      <div className="tag">
                <Navbar />
            </div>
      <div className="home">
        <div className="tag introduction">
          <p className="subheader">My Projects</p>
          <p className="header freelancer">Job Central 🤝🏻</p>
          <p className="introduction_content">A 1700+ member discord server dedicated to help people find jobs, and employers find employees.</p>
        </div>
        <div className="tag">
          <p className="header">The Story 📕</p>
          <p className="content">Founded in late 2022, I have worked to grow, manage, moderate this server to a prestigious member count. I believe this server showcases my abilities in marketing, community management, communication and relations.</p>
        </div>
        <div className="tag">
          <p className="header">Join the Server 📝</p>
          <p className="content">👉🏻 
          <a href="https://discord.gg/gjcNztpWEW" className="no_link" target="_blank" rel="noreferrer"> https://discord.gg/gjcNztpWEW </a>
           👈🏻</p>
        </div>
        <div className="tag connect">
          <p className="header">Connect With Us 🌎</p>
          <p className="content">Are you looking for high traction advertisements for yourself or company? Partnerships? Volunteering opportunities as a server moderator?</p>
          <p className="content">Get in touch at hg585@proton.me 🙏🏻</p>
        </div>
      </div>
    </div>
  );
};
