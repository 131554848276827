import "./Navbar.css"

export const Navbar = () => {
    return (
        <ul>
  <li><a href="/">Home - Interactive CV</a></li>
  <li><a href="/#hireme">Hire Me</a></li>
  <li><a href="/#news">News</a></li>
  <li><a href="/#skills">Skills</a></li>
</ul>
    )
}