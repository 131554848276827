import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Home } from './Pages/Home/Home';
import { JobCentral } from './Pages/JobCentral/JobCentral';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/job-central" element={<JobCentral />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
