const skills = {
    'React': '9/10',
    'HTML 5': '8/10',
    'CSS 3': '9/10',
    'JSON': '9/10',
    'NodeJS': '7/10',
    'Typescript': '9/10',
    'GraphQL': '5/10',
    'Solidity': '9/10',
    'Ethereum': '8/10',
    'Web3 / EVM': '9/10',
    'NPM': '9/10',
    'Python': '6/10',
    'ERC-20': '9/10',
    'ERC-721 / ERC-1155': '9/10',
    'Testing': '6/10',
    'Community Management': '8/10',
    'Community Growth': '7/10'
};
const languages = {
    'English': '9/10',
    'Spanish': '3/10',
};
const tools = {
    'Google Docs': '9/10',
    'Microsoft Word': '8/10',
    'Microsoft Powerpoint': '6/10',
    'Microsoft Outlook': '8/10',
    'Github': '9/10',
    'Algebra': '8/10',
    'Chrome / Edge': '9/10',
    'Teaching': '4/10',
    'Discord': '10/10',
    'Telegram': '3/10',
    'VSCode': '10/10',
    'Remix IDE': '9/10',
    'Truffle': '5/10'
};

export const Skills = () => {
    return (
        <div>
            <div className="skills tag" id="skills">
                <p className="header">Skills 💪🏻</p>
                <p className="skills_subheader">Coding</p>
                {Object.keys(skills).map((skill) => (
                    <div key={skill} className="skill_item">
                        <p className="skill_name">{skill}</p>
                        <p className="skill_score">{skills[skill]}</p>
                    </div>
                ))}
            </div>
            <div className="skills tag">
                <p className="skills_subheader">Languages</p>
                {Object.keys(languages).map((skill) => (
                    <div key={skill} className="skill_item">
                        <p className="skill_name">{skill}</p>
                        <p className="skill_score">{languages[skill]}</p>
                    </div>
                ))}
                <p className="skills_subheader">Tools</p>
                {Object.keys(tools).map((skill) => (
                    <div key={skill} className="skill_item">
                        <p className="skill_name">{skill}</p>
                        <p className="skill_score">{tools[skill]}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}