const Description = ({ description }) => {
    const words = description.split(' ');

    return (
        <p className="hireme_description">
            {words.map((word, index) => (
                <span key={index} className="hoverable-word">
                    {word + " "}
                </span>
            ))}
        </p>
    );
};

export const HireMe = () => {
    return(
        <div className="hireme tag" id="hireme">
                    <p className="header">Hire Me 🔨</p>
                    <p className="content">Looking for a developer?</p>
                    <p className="hireme_description">I'll make your dream project come to life for unimaginably low prices.</p>
                    <div className="product">
                        <p className="product_name">Business and Personal Websites</p>
                        <Description
                            description="I can build interactive websites similar to this one for your business or yourself to show off your skills."
                        />
                    </div>
                    <div className="product">
                        <p className="product_name">Cryptocurrency Tokens and NFTs</p>
                        <Description
                            description="I can build custom NFTs and tokens matching specifications for your project."
                        />
                    </div>
                    <div className="product">
                        <p className="product_name">Decentralized Applications</p>
                        <Description
                            description="I can build interfaces for your smart contract and write smart contracts."
                        />
                    </div>
                    <div className="product">
                        <p className="product_name">Custom Apps and Websites</p>
                        <Description
                            description="I can build your new ideas that have never been built before."
                        />
                    </div>
                    <p className="content">Get in touch at hg585@proton.me 🙏🏻</p>
                </div>
    )
}