import { Navbar } from "../../Components/Navbar/Navbar.js"
import "./Home.css";

import { Introduction } from "./Introduction/Introduction.js";
import { Skills } from "./Skills/Skills.js";
import { HireMe } from "./HireMe/HireMe.js";
import { FactsAndNews } from "./FactsAndNews/FactsAndNews.js";

import { Scroll } from "../../Components/Scroll/Scroll.js";

export const Home = () => {

    Scroll();

    return (
        <div>
            <div className="tag">
                <Navbar />
            </div>
            <div className="home">
                <Introduction />
                <Skills />
                <FactsAndNews />
                <div className="tag column">
                    <p className="header">Reviews ⭐</p>
                    <p className="content"><b>ERC1155 Website and Smart Contracts</b> - Best web3 developer out there! Was super patient and budget friendly! Created everything in a very good manner than I expected! 10/10 would hire again!</p>
                </div>
                <div className="tag column">
                    <p className="header">Side Projects 👷🏻‍♂️</p>
                    <a className="content no_link" href="/job-central"><b>Job Central</b> - A 1700+ member discord server dedicated to help people find jobs, and employers find employees.</a>
                    <br />
                    <br />
                    <a className="content no_link" href="/"><b>Interactive CV</b> - An extremely overengineered resume built with React and CSS3 to showcase my talents.</a>
                </div>
                <HireMe />
            </div>
        </div>
    )
}