const facts = {
    'Hair Color': 'Black',
    'Eye Color': 'Brown',
    'One Mile Run': '5:51',
    'Dominant Hand': 'Left',
    'Pronouns': 'He/Him',
    'Best Food': 'Cookies',
    'Age': 'Gen Z',
    'Estimated IQ': '3',
};

export const FactsAndNews = () => {
    return (
        <>
            <div className="column tag" id="news">
                <p className="header">Thoughts and News 📰</p>
                <p className="content">Coming Soon...</p>
            </div>
            <div className="column tag">
                <p className="header">Fun Facts 🎉</p>
                {Object.keys(facts).map((skill) => (
                    <div key={skill} className="skill_item">
                        <p className="skill_name">{skill}</p>
                        <p className="skill_score">{facts[skill]}</p>
                    </div>
                ))}
            </div>
        </>
    )
}